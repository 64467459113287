/* If you need to add @import statements, do so up here */

@import "jit-refresh.css";
/* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* eb-garamond-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/eb-garamond-v27-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* eb-garamond-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/eb-garamond-v27-latin-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/source-sans-3-v15-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/source-sans-3-v15-latin-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/source-sans-3-v15-latin-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-800italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 800;
  src: url('/fonts/source-sans-3-v15-latin-800italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}



.container-x-padding {
  @apply px-6 sm:px-8 lg:px-10;
}

.caption {
  @apply mt-2 text-base text-gray-700;
}

.text-link {
  @apply text-blue-700 hover:underline hover:text-blue-800 decoration-1 underline-offset-2 decoration-blue-500 hover:decoration-blue-800;
  @apply group-hover:underline group-hover:text-blue-800 group-hover:decoration-blue-800;
}

.prose {
  @apply prose-a:font-normal prose-a:text-blue-700 prose-a:decoration-1 prose-a:underline-offset-2 prose-a:decoration-blue-500;
  @apply hover:prose-a:text-blue-800 hover:prose-a:decoration-blue-800;
  @apply prose-headings:font-serif prose-headings:font-normal prose-headings:mb-4;
  @apply font-sans;
  @apply prose-blockquote:text-current prose-blockquote:border-none prose-blockquote:ps-0;
  @apply prose-h2:text-6xl;
  @apply prose-h3:text-4xl;

}

.prose :not(.not-prose) a[href*="//"]:not([href*="https://researchfordevelopment.org/"]) {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='black' aria-hidden='true'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'/%3E%3C/svg%3E");
  /* https://www.svgbackgrounds.com/tools/svg-to-css/ */
  @apply pr-[18px] bg-right bg-no-repeat bg-opacity-90;
  background-size: 1rem;
}

.prose.prose-white :not(.not-prose) a[href*="//"]:not([href*="https://researchfordevelopment.org/"]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='white' aria-hidden='true'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'/%3E%3C/svg%3E");
  /* https://www.svgbackgrounds.com/tools/svg-to-css/ */
}


.prose blockquote p:before,
.prose blockquote p:after {
  content: "";
}

.prose blockquote cite {
  @apply block not-italic text-right;
}

.prose blockquote cite:before {
  content: "— ";
}

.caption {
  @apply mt-2 text-base text-gray-700;
}

.shopify-publications .shopify-buy__btn {
  @apply border border-[#E48F00] w-full text-left h-12 flex items-center pe-4;
  @apply before:bg-[#E48F00] before:flex before:items-center before:justify-center before:w-12 before:h-12 before:text-2xl before:text-white before:me-4;
  @apply hover:bg-white;
}

.shopify-publications .shopify-buy__btn:before {
  content: "+";
}



.shopify-donate .shopify-buy__btn-and-quantity {
  @apply flex w-fit items-center border border-[#E48F00] relative;
}
.shopify-donate .shopify-buy__product__title {
  @apply hidden;
}
.shopify-donate .shopify-buy__product__price {
  @apply hidden;
}
.shopify-donate .shopify-buy__btn-and-quantity:before {
  @apply absolute inset-y-0 left-4 text-xl flex items-center;
  content: "$";
}

.shopify-donate .shopify-buy__quantity {
  @apply w-32 pr-1 pl-8 text-xl text-left h-12 inline-flex items-center;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500;
}

.shopify-donate .shopify-buy__btn {
  @apply bg-[#E48F00] text-white w-fit px-4 text-left h-12 inline-flex items-center pe-4;
}
